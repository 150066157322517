import axios from 'axios.config';

const services = {
    fetchCustomAttributes: async (user_id: any, token: any) => {
        const response = await axios.get(`/custom_attributes?id=${user_id}`, {
            headers: {
                'Conten-Type': 'application/json',
                Authorization: token,
            },
        });

        return response.data;
    },
    updatedCustomAttributes: async (data: any, id: any) => {
        const response = await axios.post(`/custom_attributes?id=${id}`, {
            headers: {
                'Content-Type': 'application/json',
            },
            data,
        });

        return response.data;
    },
};

export default services;
