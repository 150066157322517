import axios from 'axios';

const service = {
    signup: async (data: any) => {
        const response = await axios.post(
            `https://ciam-apis.herokuapp.com/create_user`,
            data
        );

        return response.data;
    },
    login: async (username: any, password: any) => {
        const response = await axios.post(
            'https://ciam-apis.herokuapp.com/auth_user',
            {
                data: {
                    username,
                    password,
                },
            }
        );
        return response.data;
    },

    create_session: async (sessionToken: any) => {
        const url = `https://gsabhi-dev.onelogin.com/session_via_api_token`;
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                Accept: '*/*',
            },
            withCredentials: true,
        };

        const response = await axios.post(
            url,
            { session_token: sessionToken },
            axiosConfig
        );
        return response;
    },
};

export default service;
