import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';
import {
    Button as AntdButton,
    Form,
    Input as AntdInput,
    Select as AntdSelect,
} from 'antd';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    background-color: #2a323c;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FormContainer = styled.div`
    background-color: #323c48;
    width: 50%;
    padding: 25px;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 10%);
    border-radius: 0.25rem;

    @media (min-width: 500px) {
        width: 420px;
    }

    @media (max-width: 500px) {
        width: 90%;
    }
`;

export const FormBody = styled(Form)`
    padding: 15px 10px;
`;

const getColor = (type?: any): string => {
    switch (type.color) {
        case 'success':
            return type.theme.color.success;
        case 'danger':
            return type.theme.color.danger;
        default:
            return type.theme.button.primary;
    }
};

export const Button = styled(AntdButton)<{ color?: String }>`
    background-color: ${(props) => getColor(props)};
    border: 1px solid ${(props) => getColor(props)};
    color: #ffffff;
    width: 100%;
    margin-bottom: 15px;
    &:hover {
        background-color: ${(props) => getColor(props)};
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 30px;
`;

export const Input = styled(AntdInput)`
    background-color: #3b4654 !important;
    color: #ffffff;
    border: 1px solid #4a5869;

    &:hover {
        border-color: #4a5869;
    }
    &:focus {
        border-color: rgb(133, 133, 133);
    }
`;

export const FormHeader = styled(Text)`
    font-size: 18px;
    padding: 10px 0;
    text-align: center;
    color: #98a4b9 !important;
    display: block;
`;

export const Brand = styled.div`
    padding: 10px 0;
    font-size: 20px;
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
`;

export const TextMuted = styled(Link)`
    font-size: 14px;
    color: #98a4b9 !important;
    text-decoration: none;
    background-color: transparent;
`;

export const Logo = styled.img`
    height: 30px;
    margin: 10px;
`;

export const QuestionsContainer = styled.div`
    padding: 0 10px;
`;

export const QuestionBody = styled.div`
    display: grid;
    grid-template-columns: 8% 70% 32%;
    color: #98a4b9 !important;
    margin-bottom: 15px;
`;

export const AnswerPortion = styled.div`
    text-align: center;
`;

export const QuestionIndex = styled.div``;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

export const Select = styled(AntdSelect)``;
