export const getColor = (type?: string): string => {
    switch (type) {
        case 'success':
            return '#28a745 !important';
        case 'danger':
            return '#dc3545 !important';
        default:
            return '#04a2b3 !important';
    }
};
