import React from 'react';
import { Brand, Logo, HeaderContainer } from './styled';
import logo from 'assets/shop.png';
import Logout from '../Logout';

const Header = () => {
    // return <AntdHeader> <Logo > LOGO </Logo> <LogoutButton />   </AntdHeader>
    return (
        <HeaderContainer>
            <Brand>
                <Logo src={logo} alt="logo" /> ClothesMart
            </Brand>
            <Logout />
        </HeaderContainer>
    );
};

export default Header;
