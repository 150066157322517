import React from 'react';

import Fadein from 'react-fade-in';

import { Wrapper, Backdrop, ButtonWrapper, StyledLink } from './styled';

const LandingPage = () => {
    return (
        <Wrapper>
            <Backdrop />
            <ButtonWrapper>
                <Fadein>
                    <StyledLink to="/login"> Login</StyledLink>
                </Fadein>

                <Fadein>
                    <StyledLink to="/tryit">TryIt Now</StyledLink>
                </Fadein>
            </ButtonWrapper>
        </Wrapper>
    );
};

export default LandingPage;
