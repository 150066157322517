import Axios from 'axios';
import React, { useContext } from 'react';
import { notification, Button as AntdButton } from 'antd';
import styled from 'styled-components';
import { AuthenticationContext, deleteSession } from 'utils';

const Button = styled(AntdButton)`
    float: right;
    border: 2px solid #eee;
    color: #eee;
    background: ${(props) => props.theme.color.gray};
    width: 80px;
    display: flex;
    text-transform: uppercase;
    transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1),
        -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    letter-spacing: 1px;

    &:focus {
        background-color: #001529;
    }

    &:hover {
        border-color: #ffffff;
        color: ${(props) => props.theme.color.gray};
        background: #ffffff;
    }
`;

const LogoutButton = () => {
    const { setAuthenticated, setScreenLoading } = useContext(
        AuthenticationContext
    );

    const logOut = async () => {
        setScreenLoading(true);
        Axios.get('https://ciam-apis.herokuapp.com/logout')
            .then((res) => {
                notification.success({
                    message: 'User successfully Logged out!',
                });
                deleteSession();
                setAuthenticated(false);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setScreenLoading(false);
            });
    };

    return (
        <Button onClick={logOut} size="large">
            Log Out
        </Button>
    );
};

export default LogoutButton;
