import React from 'react';
import FadeIn from 'react-fade-in';
import { notification, Form } from 'antd';

import {
    FormContainer,
    Wrapper,
    Input,
    Button,
    FormBody,
    Brand,
    Logo,
    TextMuted,
    Footer,
    FormHeader,
} from 'common/styled';
import logo from 'assets/shop.png';

import service from './services';
import { AuthenticationContext } from 'utils';
import Spinner, { SpinnerWrapper } from 'components/LoadingScreen/Loader';

const Login = () => {
    const [form] = Form.useForm();
    const { setUser, setScreenLoading, setAuthenticated } = React.useContext(
        AuthenticationContext
    );

    const [loading, setLoading] = React.useState(false);

    const login = async (values: any) => {
        setLoading(true);

        const { username, password } = values;

        try {
            const response = await service.login(username, password);

            const session_token = response.data[0].session_token;

            setUser(response.data[0].user);

            const session_response = await service.create_session(
                session_token
            );

            notification.success({
                message: 'Successfully logged in',
            });

            setScreenLoading(true);
            setAuthenticated(true);
            if (session_response) {
                window.location.replace(
                    'https://gsabhi-dev.onelogin.com/oidc/2/auth?client_id=20b80d00-5410-0139-fb93-0ac6abe4ea43185165&redirect_uri=https://ciam.pages.dev/&response_type=id_token&scope=openid&nonce=abcbde'
                );
            }
        } catch (err) {
            notification.error({
                message: 'something went wrong!',
            });
            console.error(err);
        } finally {
            setScreenLoading(false);
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <Wrapper>
                <FadeIn>
                    <FormContainer>
                        <Brand>
                            <Logo src={logo} alt="Logo" />
                            ClothesMart
                        </Brand>
                        <FormHeader>Sign In</FormHeader>
                        <SpinnerWrapper>
                            <Spinner />
                        </SpinnerWrapper>
                    </FormContainer>
                </FadeIn>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <FadeIn>
                <FormContainer>
                    <Brand>
                        <Logo src={logo} alt="Logo" />
                        ClothesMart
                    </Brand>
                    <FormHeader>Sign In</FormHeader>
                    <FormBody form={form} onFinish={login}>
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter username',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Username"
                                autoComplete="off"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter password',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Password"
                                type="password"
                                autoComplete="off"
                                size="large"
                            />
                        </Form.Item>

                        <Button
                            htmlType="submit"
                            size="large"
                            color="default"
                            type="primary"
                            loading={loading}
                        >
                            Login
                        </Button>
                    </FormBody>
                    <Footer>
                        <TextMuted to="/forgotpassword">
                            Forgot your password?
                        </TextMuted>
                        <TextMuted to="/register">Create an Account</TextMuted>
                    </Footer>
                </FormContainer>
            </FadeIn>
        </Wrapper>
    );
};

export default Login;
