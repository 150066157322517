import React from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { createBrowserHistory } from 'history';

import './App.css';
import { lightTheme } from 'theme';

import { AuthenticationContext } from 'utils';
import RestrictedRoute, {
    LOGGED_IN_USERS,
    LOGGED_OUT_USERS,
} from 'components/RestrictedRoute';
import ConditionalRoute from 'components/ConditionalRoute';

import LoadingScreen from 'components/LoadingScreen';
import LandingPage from 'pages/Landing';
import HomePage from './pages/Home';
import TryItNowPage from './pages/TryItNow';
import LoginPage from 'pages/LoginPage';
import RegistrationPage from 'pages/RegisterPage';
import ForgotPasswordPage from 'pages/ForgotPassword';
import Axios from 'axios';
import { notification } from 'antd';

export const history = createBrowserHistory();

function App() {
    const [authenticated, setAuthenticated] = React.useState(false);
    const [user, setUser] = React.useState<any>(null);
    const [token, setToken] = React.useState<any>(null);
    const [isScreenLoading, setScreenLoading] = React.useState(false);

    const checkForSession = async () => {
        setScreenLoading(true);
        try {
            const response = await Axios.get(
                'https://ciam-apis.herokuapp.com/check_session'
            );

            const { user_access_token } = response.data;

            setToken(user_access_token);

            notification.success({
                message: 'User already logged in!',
            });

            setAuthenticated(true);
        } catch (error) {
            console.error(error.response?.data);

            // notification.error({
            //     message: error.response?.data ?? 'Session not found',
            // });
        } finally {
            setScreenLoading(false);
        }
    };

    React.useEffect(() => {
        checkForSession();
    }, []);

    return (
        <AuthenticationContext.Provider
            value={{
                authenticated,
                setAuthenticated,
                user,
                setUser,
                token,
                setToken,
                isScreenLoading,
                setScreenLoading,
            }}
        >
            <ThemeProvider theme={lightTheme}>
                <Router history={history}>
                    <Switch>
                        {isScreenLoading && (
                            <Route path="/" component={LoadingScreen} />
                        )}

                        <ConditionalRoute
                            exact
                            path="/"
                            loginComponent={HomePage}
                            logoutComponent={LandingPage}
                        />

                        <Route exact path="/" component={LandingPage} />
                        <Route path="/tryit" component={TryItNowPage} />
                        <RestrictedRoute
                            exact
                            path="/login"
                            component={LoginPage}
                            onlyAllow={LOGGED_OUT_USERS}
                        />
                        <RestrictedRoute
                            path="/register"
                            component={RegistrationPage}
                            onlyAllow={LOGGED_OUT_USERS}
                        />
                        <RestrictedRoute
                            path="/forgotpassword"
                            component={ForgotPasswordPage}
                            onlyAllow={LOGGED_OUT_USERS}
                        />
                        <RestrictedRoute
                            path="/"
                            component={HomePage}
                            onlyAllow={LOGGED_IN_USERS}
                        />
                    </Switch>
                </Router>
            </ThemeProvider>
        </AuthenticationContext.Provider>
    );
}

export default App;
