import baseStyled, { ThemedStyledInterface } from 'styled-components';

export const screenSize = {
    xs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
};

interface Theme {
    primaryColor: string;
    secondaryColor: string;
    button: {
        primary: string;
        secondary: string;
        primaryFontColor: string;
        secondaryFontColor: string;
        shadow: string;
    };
    color: {
        blue: string;
        indigo: string;
        purple: string;
        pink: string;
        red: string;
        orange: string;
        yellow: string;
        green: string;
        teal: string;
        cyan: string;
        white: string;
        gray: string;
        grayDark: string;
        primary: string;
        secondary: string;
        success: string;
        info: string;
        warning: string;
        danger: string;
        light: string;
        dark: string;
    };
}

export const lightTheme: Theme = {
    primaryColor: '#2a323c',
    secondaryColor: '#323c48',

    button: {
        primary: '#04a2b3',
        secondary: '#7E7C8A',
        primaryFontColor: '#FFFFFF',
        secondaryFontColor: '#FFFFFF',
        shadow: '0px 3px 3px #00000029',
    },

    color: {
        blue: '#007bff',
        indigo: '#6610f2',
        purple: '#6f42c1',
        pink: '#e83e8c',
        red: '#dc3545',
        orange: '#fd7e14',
        yellow: '#ffc107',
        green: '#28a745',
        teal: '#20c997',
        cyan: '#17a2b8',
        white: '#fff',
        gray: '#6c757d',
        grayDark: '#343a40',
        primary: '#007bff',
        secondary: '#6c757d',
        success: '#28a745',
        info: '#17a2b8',
        warning: '#ffc107',
        danger: '#dc3545',
        light: '#f8f9fa',
        dark: '#343a40',
    },
};

export const styled = baseStyled as ThemedStyledInterface<Theme>;
