import styled from 'styled-components';

export const Logo = styled.img`
    height: 20px;
`;

export const Profile = styled.div``;

export const Logout = styled.div`
    float: right;
    border: 1px solid #ffffff;
    padding: 5px 10px;
    color: #ffffff;
    border-radius: 3px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    &:hover {
        background-color: #ffffff;
        color: ${(props) => props.theme.color.gray};
    }
`;

export const Brand = styled.div`
    float: left;
    color: #ffffff;
    font-style: italic;
    font-size: 15px;
    padding: 0 10px;
`;

export const HeaderContainer = styled.div`
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 10%);
    width: 100%;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.color.gray};
    height: 50px;
    justify-content: space-between;
    padding: 5px 10px;
`;
