import React from 'react';
// import { AuthenticationContext } from 'utils';
import { Card, CardBody } from './styled';

const HomeContent = () => {
    // const { user, token } = React.useContext(AuthenticationContext);

    return (
        <>
            <Card to="/changepassword">
                <CardBody>
                    <span>Change</span> <span>Password</span>
                </CardBody>
            </Card>
            <Card to="/changepreferences">
                <CardBody>
                    <span>Change</span> <span>user preferences</span>
                </CardBody>
            </Card>
        </>
    );
};

export default HomeContent;
