import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import { AuthenticationContext } from '../../utils/context';

const ConditionalRoute = (props: any) => {
    const { authenticated } = useContext(AuthenticationContext);
    const { loginComponent, logoutComponent, ...rest } = props;

    if (!loginComponent || !logoutComponent) {
        throw Error('component is undefined');
    }

    const LoginComponent = loginComponent; // JSX Elements have to be uppercase.
    const LogoutComponent = logoutComponent; // JSX Elements have to be uppercase.

    /*
            Since we need to render the requested component if
                loggedIn = true  then render => LoginComponent
            else
                render => logoutComponent

        */
    if (authenticated) {
        return (
            <Route
                {...rest}
                render={(props) => <LoginComponent {...props} />}
            />
        );
    } else {
        return (
            <Route
                {...rest}
                render={(props) => <LogoutComponent {...props} />}
            />
        );
    }
};

/**
 * ConditionROute Component
 *
 * This is a wrapper on Router Component to only allow when set conditions are matched.
 * - If the user is logged in
 *      - and LoginComponent is allowed only for LOGGED_IN_USERS
 * - If the user is logged out
 *      - and LogoutComponent is allowed only for LOGGED_OUT_USERS then render it
 *
 */
export default ConditionalRoute;
