import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthenticationContext } from '../../utils/context';

enum ALLOWED_CASES {
    LOGGED_IN_USERS = 1,
    LOGGED_OUT_USERS = 0,
}

export const LOGGED_IN_USERS = ALLOWED_CASES.LOGGED_IN_USERS;
export const LOGGED_OUT_USERS = ALLOWED_CASES.LOGGED_OUT_USERS;

const RestrictedRoute = (props: any) => {
    const { component, ...rest } = props;
    const { authenticated } = useContext(AuthenticationContext);

    if (!component) {
        throw Error('component is undefined');
    }

    const Component = component; // JSX Elements have to be uppercase.
    const loginPath = JSON.parse(process.env.REACT_APP_ON_PREMISE || 'false')
        ? '/'
        : '/login';

    /*
            Since we need to render the requested component if
                userLoggedIn = true && onlyAllow = true (LOGGED_IN_USERS)
                                    OR
                userLoggedIn = false && onlyAllow = false (LOGGED_OUT_USERS)

            We need a logical biconditional, which outputs true only when both inputs are the same
        */
    if (authenticated === Boolean(props.onlyAllow)) {
        return <Route {...rest} render={(props) => <Component {...props} />} />;
    }

    if (authenticated && props.onlyAllow === ALLOWED_CASES.LOGGED_OUT_USERS) {
        // user is logged in, but resource is allowed for LOGGED_OUT_USERS only
        // don't allow the user look at the page, redirect to "HomePage"
        // e.g. once logged in, user shouldn't be able to look at the "LoginPage"
        return (
            <Route
                {...rest}
                render={(props) => {
                    return <Redirect to={{ pathname: '/' }} />;
                }}
            />
        );
    } else {
        // user is logged out, but resource is allowed for LOGGED_IN_USERS only
        // don't allow the user look at the page, redirect to "LoginPage"
        // e.g. once logged out, user shouldn't be able to look at the "HomePage"
        return (
            <Route
                {...rest}
                render={(props) => (
                    <Redirect
                        to={{
                            pathname: loginPath,
                            search: `next=${props.location.pathname}`,
                        }}
                    />
                )}
            />
        );
    }
};

export default RestrictedRoute;
