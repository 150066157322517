import React from 'react';
import {
    FormContainer,
    Wrapper,
    Brand,
    FormHeader,
    Logo,
    FormBody,
    Input,
    InstructionText,
    CloseButton,
} from './styled';
import logo from 'assets/shop.png';
import { Button } from 'pages/RegisterPage/styled';
import FadeIn from 'react-fade-in';

const { Item } = FormBody;

const ForgotPassword = () => {
    const [closeInstruction, setCloseInstruction] = React.useState('block');

    const onClickHandler = () => {
        setCloseInstruction('none');
    };

    return (
        <Wrapper>
            <FadeIn>
                <FormContainer>
                    <Brand>
                        <Logo src={logo} alt="Logo" />
                        ClothesMart
                    </Brand>
                    <FormHeader> Reset Password</FormHeader>

                    <FormBody>
                        <InstructionText display={closeInstruction}>
                            <CloseButton onClick={onClickHandler} />
                            Enter your <strong>Email</strong> and instruction
                            will be sent to you!
                        </InstructionText>
                        <Item>
                            <Input placeholder="Email" />
                        </Item>
                        <Button
                            type="primary"
                            color="default"
                            htmlType="submit"
                            size="large"
                        >
                            Send Mail
                        </Button>
                    </FormBody>
                </FormContainer>
            </FadeIn>
        </Wrapper>
    );
};

export default ForgotPassword;
