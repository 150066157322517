import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';
import { Button as AntdButton, Form, Input as AntdInput } from 'antd';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    background-color: #2a323c;
`;

export const FormContainer = styled.div`
    background-color: #323c48;
    width: 50%;
    padding: 25px;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 10%);
    border-radius: 0.25rem;

    @media (min-width: 500px) {
        width: 420px;
    }

    @media (max-width: 500px) {
        width: 90%;
    }
`;

export const FormBody = styled(Form)`
    padding: 15px 10px;
`;

const getColor = (type?: string): string => {
    switch (type) {
        case 'success':
            return '#28a745 !important';
        case 'danger':
            return '#dc3545 !important';
        default:
            return '#04a2b3 !important';
    }
};

export const Button = styled(AntdButton)<{ color?: String }>`
    background-color: ${(props) => getColor(props.color)};
    border: 1px solid ${(props) => getColor(props.color)};
    color: #ffffff;
    width: 100%;
    margin-bottom: 15px;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 30px;
`;

export const Input = styled(AntdInput)`
    background-color: #3b4654 !important;
    color: #ffffff;
    border: 1px solid #4a5869;

    &:hover {
        border-color: #4a5869;
    }
    &:focus {
        border-color: rgb(133, 133, 133);
    }
`;

export const FormHeader = styled(Text)`
    font-size: 18px;
    padding: 10px 0;
    text-align: center;
    color: #98a4b9 !important;
    display: block;
`;

export const Content = styled.div`
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
`;

export const Card = styled(Link)`
    border-radius: 0.25rem;
    width: 250px;
    height: 250px;
    text-transform: uppercase;
    font-size: 25px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    border: 2px solid ${(props) => props.theme.color.white} !important;
    text-align: center;
    font-weight: 500;
    color: ${(props) => props.theme.color.white} !important;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
    /* box-shadow: 0 3px 6px rgba(255, 255, 255, 0.16),
        0 3px 6px rgba(255, 255, 255, 0.23); */
    span {
        display: block;
    }
    a {
        color: ${(props) => props.theme.color.white} !important;
    }
    &:hover {
        transform: scale(1.01);
        color: ${(props) => props.theme.color.grayDark} !important;
        background-color: ${(props) => props.theme.color.white};
        a {
            color: ${(props) => props.theme.color.grayDark} !important;
        }
    }
`;

export const CardBody = styled.div``;
