import styled from 'styled-components';
import { Form, Input as AntdInput, Button as AntdButton } from 'antd';
import Text from 'antd/lib/typography/Text';

import { Link } from 'react-router-dom';

import { getColor } from 'utils';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SpinnerWrapper = styled.div`
    padding: 15px;
    display: flex;
    justify-content: center;
`;

export const FormRenderer = styled.div`
    background-color: #323c48;
    width: 50%;
    padding: 25px;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 10%);
    border-radius: 0.25rem;

    @media (min-width: 500px) {
        width: 420px;
    }

    @media (max-width: 500px) {
        width: 90%;
    }
`;

export const FormBody = styled(Form)`
    padding: 15px 10px;
`;

export const TextMuted = styled(Link)`
    font-size: 14px;
    color: #98a4b9 !important;
    text-decoration: none;
    background-color: transparent;
`;

export const FormHeader = styled(Text)`
    font-size: 18px;
    padding: 10px 0;
    text-align: center;
    color: #98a4b9 !important;
    display: block;
`;

export const Input = styled(AntdInput)`
    background-color: #3b4654 !important;
    color: #ffffff;
    border: 1px solid #4a5869;

    &:hover {
        border-color: #4a5869;
    }
    &:focus {
        border-color: rgb(133, 133, 133);
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
`;

export const Button = styled(AntdButton)<{ color?: String }>`
    background-color: ${(props) => getColor(props.color)};
    border: 1px solid ${(props) => getColor(props.color)};
    color: #ffffff !important;
    width: 45%;
    margin-bottom: 15px;

    &:hover {
        color: #ffffff;
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;
