import React from "react";
import Loader,{Backdrop,SpinnerContainer,Loadtext} from "./Loader"


const LoadingScreen =()=>{
    return <div>
        <Backdrop/>
        <SpinnerContainer>
            <Loader></Loader>
            <Loadtext> Loading </Loadtext>
        </SpinnerContainer>

    </div>

}

export default LoadingScreen