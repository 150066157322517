import axios from 'axios.config';

const services = {
    resetLink: async (data: any) => {
        const response = await axios.post('/reset_password', {
            headers: {
                'Content-Type': 'application/json',
            },
            data,
        });

        return response.data;
    },
};

export default services;
