// import { Layout } from "antd"
import Header from 'components/Header';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router';
import HomeContent from './HomeContent';

import ChangePasswordPage from 'pages/ChangePassword';
import ChangePreferncesPage from 'pages/ChangePreferences';
import { Content, Wrapper } from './styled';
import { AuthenticationContext } from 'utils';
import Axios from 'axios';
// const {Content} = Layout

const Home = (props: any) => {
    const { match } = props;
    const { user, setUser } = useContext(AuthenticationContext);

    React.useEffect(() => {
        const get_user_info = async () => {
            try {
                const response = await Axios.get(
                    'https://ciam-apis.herokuapp.com/user_info'
                );
                setUser(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        if (!user) {
            get_user_info();
        }
    }, [setUser, user]);

    return (
        <Wrapper>
            <Header />
            <Content>
                <Switch>
                    <Route
                        exact
                        path={`${match.url}changepassword`}
                        component={ChangePasswordPage}
                    />
                    <Route
                        exact
                        path={`${match.url}changepreferences`}
                        component={ChangePreferncesPage}
                    />
                    <Route exact path={match.url} component={HomeContent} />
                </Switch>
            </Content>
        </Wrapper>
    );
};

export default Home;
